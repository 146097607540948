import React, {useState} from 'react';
import '../config.js';
import Progress from '../components/progress.js';
import DefaultHeader from '../components/defaultHeader.js';
import img from '../assets/faq.jpg';
import Association from '../components/association.js';

function Faq() {
    const [loading, setLoading] = useState(false);

    if(loading){
        return <Progress/>;
    }else{
        return(
            <div className="about-content">
                <DefaultHeader img={img} heading="FAQs"/>

                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <h1 className='text-center'>FAQs</h1>
                    </div>
                </div>

                <div className="container faq-container mt-5">
                    <div className="row">
                        <div className='col-md-12'>
                            <h4><i className="fa fa-hand-o-right"></i> 1. What services does Keppa Travel offer?</h4>
                        <p> Keppa Travel offers a wide range of travel services, including customized vacation or tour packages,
guided tours, flights, hotel bookings, and transportation. With over 20 years of experience, we
specialize in both leisure and business travel.</p>
<h4><i className="fa fa-hand-o-right"></i> 2. How do I book a trip with Keppa Travel?</h4>
<p>You can easily book your travel services from www.keppatravel.com. For that you have to choose
your preferred tour plan and fill out the form “Plan Your Trip” against that particular plan, in the
detailing box you can write your comments /special requirements or any other information that you
want to share with Keppa Travel and then submit this form. Your work is done and further, our best
travel expert will contact you and send you a comprehensive tour package related to a detailed
travel plan, hotel information, guide, or transport, etc. on your phone or email you provided.</p>
<h4><i className="fa fa-hand-o-right"></i> 3. Can I customize a travel package?</h4>
<p>Yes! At Keppa Travel, we pride ourselves on creating tailor-made itineraries. Whether you want to
add specific destinations, experiences, or accommodations, our team will work with you to design a
trip that suits your preferences and budget.</p>
<h4><i className="fa fa-hand-o-right"></i> 4. What destinations does Keppa Travel specialize in?</h4>
<p>We offer trips to a variety of destinations around India, Sri Lanka, Nepal, Bhutan, Singapore,
Malaysia, and Thailand, including popular vacation spots, hidden gems, and niche experiences.
Whether you're looking for a beach getaway, cultural exploration, or adventure travel, we've got
you covered.</p>
<h4><i className="fa fa-hand-o-right"></i> 5. Do you offer group travel or corporate trips?</h4>
<p>Yes, Keppa Travel organizes the tour to a group of people or corporations. Whether it&#39;s a family
reunion, team-building event, or incentive trip, we provide comprehensive planning and
management to ensure a seamless experience for all participants.</p>
<h4><i className="fa fa-hand-o-right"></i> 6. What are the payment options for booking a trip?</h4>
<p>We accept all major credit cards, bank transfers, and payment plans. If you need to split the cost of
your trip into installments, please contact our team to discuss available options.</p>
<h4><i className="fa fa-hand-o-right"></i> 7. What is your cancellation and refund policy?</h4>
<p>Our cancellation and refund policies vary depending on the trip and service provider (e.g., hotels,
airlines). We recommend checking the specific terms when booking. Our team will guide you
through the process and help with any necessary changes.</p>
<h4><i className="fa fa-hand-o-right"></i> 8. How far in advance should I book my trip?</h4>
<p>We recommend booking at least 3 to 6 months in advance, especially for peak travel seasons or
popular destinations. However, if you're planning a last-minute getaway, we'll do our best to
accommodate your needs with available options.</p>
<h4><i className="fa fa-hand-o-right"></i> 9. How can I contact Keppa Travel for more information?</h4>
<p>You can reach us by filling out the contact form on our website, emailing info@keppatravel.com, or
calling our customer service line at [+919220419122]. We're available at your service on the Phone at
any time.</p>
<h4><i className="fa fa-hand-o-right"></i> 10. Do you offer travel deals or discounts?</h4>
<p>Yes! Keppa Travel regularly features special offers, early bird discounts, and seasonal promotions.</p>
<h4><i className="fa fa-hand-o-right"></i> 11. What should I do if I need to make changes to my booking?</h4>
<p>If you need to modify your booking, please contact our customer service team as soon as possible.
We will work with our partners to adjust your itinerary, but please note that changes may be subject
to fees depending on the timing and services involved.</p>
<h4><i className="fa fa-hand-o-right"></i> 12. Do you provide airport transfers and transportation services?</h4>
<p>Yes, we offer airport transfers, car rentals, and other transportation services at many destinations.
These can be included in your travel package or booked separately.</p>
<h4><i className="fa fa-hand-o-right"></i> 13. Are your tours suitable for families with children?</h4>
<p>Absolutely! We offer a range of family-friendly tours and vacation packages, with activities and
accommodations suited for all age groups. Whether you&#39;re looking for kid-friendly excursions or
relaxing resorts, we can help design a family trip everyone will enjoy.</p>
<h4><i className="fa fa-hand-o-right"></i> 14. Can you accommodate special dietary requirements or requests?</h4>
<p>Yes, we strive to accommodate all dietary needs, including vegetarian, vegan, gluten-free, and other
restrictions. Please let us know in advance, and we'll make arrangements with hotels and
restaurants along your journey.</p>
<h4><i className="fa fa-hand-o-right"></i> 15. What is included in a typical vacation package?</h4>
<p>Our vacation packages generally include accommodations, transportation, guided tours, and certain
meals, depending on the destination and package selected. Some packages may also include
entrance fees to attractions, special activities, or cultural experiences. Check the details for each
package on our website or contact us for specific inclusions.</p>
<h4><i className="fa fa-hand-o-right"></i> 16. Can I book a trip as a gift for someone else?</h4>
<p>Yes, you can also book trips on behalf of friends or family members. Our team can help you arrange
a memorable experience for your loved ones, complete with personalized touches.</p>
<h4><i className="fa fa-hand-o-right"></i> 17. What happens if my flight is delayed or canceled?</h4>
<p>In the event of a flight delay or cancellation, our team will assist you with rebooking your flight (If
earlier the flight is booked by Keppa Travel) or making alternative travel arrangements. We also
advised purchasing travel insurance to cover any unexpected changes to your plans.</p>
<h4><i className="fa fa-hand-o-right"></i> 18. Do you offer support during my trip in case of emergencies or issues?</h4>
<p>Yes, that is our prime duty. Keppa Travel provides 24/7 support during your trip to assist with any
emergencies, travel disruptions, or other concerns. You can contact our customer service team at
any time for immediate help, ensuring a smooth and stress-free experience.</p>
<p>If you do not find the answers to your desired questions in the above paragraphs, do not worry,
WhatsApp or email us right now so that you can get correct and accurate information about your
questions and we will also be happy that we have helped you.</p>



                        </div>
                    </div>
                </div>
                <Association/>
            </div>
        )
    }
}

export default Faq;