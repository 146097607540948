import React, {useState} from 'react';
import {useNavigate } from 'react-router-dom';
import '../config.js';

function Search(props) {
  const type = props.type;
  const navigate = useNavigate();
  let v = props.value;
  if(v === null || v === undefined){
    v = '';
  }
  const [q, setQ] = useState(v);

  const searchEnter = (e) => {
    if(e.key == 'Enter' && q.length > 2){
      navigate("/search?q="+q);
    }
  }

  const search = (e) => {
    if(q.length > 2){
      navigate("/search?q="+q);
    }
  }

  let searchClass = "";
  let placeholder = "Search your next destination";
  if(type === 'nav'){
    searchClass = " small";
    placeholder = "Search";
  }
  return(
    <div className={'search-container' + searchClass}>
      {type != 'nav' &&
        <h1 className='mb-3 text-poppins-bold'>Welcome to Keppa Travel</h1>
      }
      <div className='input-container'>
        <i className="fa fa-search" onClick={(e) => search(e)}></i>
        <input type="text" value={q} className='form-control' placeholder={placeholder} onKeyDown={(e) => searchEnter(e)} onChange={e => setQ(e.target.value)}/>
      </div>
      {type != 'nav' &&
        <h4 className='mt-3'>Explore the immensity of India</h4>
      }
    </div>
  )
}

export default Search;