import React, {useState} from 'react';
import '../config.js';
import Progress from '../components/progress.js';
import DefaultHeader from '../components/defaultHeader.js';
import img from '../assets/contact.jpg';
import Association from '../components/association.js';

function Contact() {
    const [loading, setLoading] = useState(false);

    if(loading){
        return <Progress/>;
    }else{
        return(
            <div className="about-content">
                <DefaultHeader img={img} heading="Contact Us"/>

                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <h1 className='text-center'>Contact Us</h1>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="row">
                        <div className='col-md-8'>
                            <p>When thinking about any topic, you may require a few recommendations from somebody who knows the goals better and can help you set up things appropriately for your curiosity or queries. We will do likewise for you and promise to help you to the best of our knowledge. You may contact us via email and be assured of the speediest conceivable answer to your questions or queries with all applicable data. One can contact us for their questions, answers, needs, or some other help to our below-specified mail ID.</p>
                            <p>Contact us for further information.</p>
                            <p><strong>303, Aradhana Apt. T-Extn. Plot No- 90-93, Solanki Road</strong></p>
                            <p><strong>Near Dheeraj Property Uttam Nagar (W) New Delhi- India- 110059</strong></p>
                            <p><strong>Ph- <a href="tel:+919220419122" target="_blank">+91 9220419122</a>  | <a href="tel:+919953249821" target="_blank">+91 9953249821</a></strong></p>
                            <p><strong>What's App- <a href="https://wa.me/+919220419122" target="_blank">+91 9220419122</a></strong></p>
                            <p><strong>Email- <a href="mailto: info@keppatravel.com"> info@keppatravel.com</a>, <a href="mailto: sales@keppatravel.com"> sales@keppatravel.com</a>,</strong></p>
                            <p><strong className="no-visible">Email- </strong><strong><a href="mailto: tourtravel000@gmail.com"> tourtravel000@gmail.com</a>, <a href="mailto: ops@keppatravel.com"> ops@keppatravel.com</a></strong></p>
                            <p><strong>Website- <a href="www.keppatravel.com">www.keppatravel.com</a></strong></p>
                            <p><strong>Office Hours- </strong>10 AM-6.30 PM (Mon-Fri)</p>
                            <p><strong className="no-visible">Office Hours- </strong>10 AM-2 PM (Sat), Sunday Closed</p>
                        </div>
                        <div className='col-md-4'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d56041.44764896485!2d77.01302414695128!3d28.61205992355306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m5!1s0x390d052a4880ba61%3A0x63e035af61b7b68c!2sAradhana%20Apartments%2C%20T-255%2C%20Solanki%20Rd%2C%20Jain%20Colony%2C%20Block%20G%2C%20Vishwas%20Park%2C%20Uttam%20Nagar%2C%20New%20Delhi%2C%20Delhi%2C%20110059!3m2!1d28.611990799999997!2d77.0542237!4m0!5e0!3m2!1sen!2sin!4v1730204473963!5m2!1sen!2sin" width="100%" height="450" style={{border:1}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                </div>
                <Association/>
            </div>
        )
    }
}

export default Contact;