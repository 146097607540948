import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class Footer extends Component {

  componentDidMount(){

  }

  render() {
    const year = new Date().getFullYear();
    return(
          <footer className="sticky-footer bg-white">
            <div className="container my-auto pt-3">
              <div className='row mb-3'>
                <div className="col-md-12">
                  <div className='icons justify-content-center text-center'>
                    <div className='icon'><a href="https://www.facebook.com/profile.php?id=61566950087396&sk=about" target="_blank"><i className="fa fa-facebook"></i></a></div>
                    <div className='icon'><a href="https://www.instagram.com/keppatravel2008/profilecard/?igsh=ZzBvcXdnbDBtbGVv" target="_blank"><i className="fa fa-instagram"></i></a></div>
                    <div className='icon'><a href="https://www.tripadvisor.in/Profile/Keppa_Travel" target="_blank"><i className="fa fa-tripadvisor"></i></a></div>
                    <div className='icon'><a href="https://www.youtube.com/@keppa2008/videos" target="_blank"><i className="fa fa-youtube"></i></a></div>
                    <div className='icon'><a href="https://wa.me/+919220419122" target="_blank"><i className="fa fa-whatsapp"></i></a></div>
                  </div>
                  <div className='row mt-5 justify-content-center'>
                    <div className='col-md-4 text-center'>
                      <p><Link to={"/about"}>About Us</Link></p>
                      <p><Link to={"/company"}>Company Profile</Link></p>
                    </div>
                    <div className='col-md-4 text-center'>
                      <p><Link to={"/contact"}>Contact Us</Link></p>
                      <p><Link to={"/faq"}>FAQ</Link></p>
                    </div>
                    <div className='col-md-4 text-center'>
                      <p><Link to={"/terms"}>Terms & Condition</Link></p>
                      <p><Link to={"/privacy-policy"}>Privacy Policy</Link></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="copyright text-center my-auto">
                <span>&#169;{year} All rights reserved to Keppa Travel</span>
              </div>
            </div>
          </footer>
    )
  }
}

export default Footer;