import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { alertService } from '../services/alert.service.js';
import MenuListItem from './menuListItem.js';
import Search from './search.js';
import logo from '../assets/logo.png';
import { useDispatch } from 'react-redux'
import { update } from '../slices/enquiry.js';

function Topnav(props) {
  //const TRANSPARENT = "#ffffff66";
  const TRANSPARENT = "#fff";
  const [menu, setMenu] = useState([]);
  const [navColor, setNavColor] = useState(TRANSPARENT);
  const [brandImage, setBrandImage] = useState('regular');
  const dispatch = useDispatch();

  useEffect(()=>{
    let uri = global.config.api_server+'menu';
      axios.get(uri)
      .then(res => {
          if(res.status === 200 && res.data.status === "success"){
          const temp = res.data.menu;
          setMenu(temp)
          }
      }).catch(err => {
          alertService.error(err);
      });

      const colorChange = () => {
        if(window.scrollY < 5 && window.innerWidth > 900){
          setNavColor(TRANSPARENT);
          //setBrandImage("branded");
          setBrandImage("regular");
        }else{
          setNavColor("#fff");
          setBrandImage("regular");
        }
      }
      window.addEventListener("scroll", colorChange);
      return () => window.removeEventListener("scroll", colorChange);
    }, []);
  
  return(
    <nav className="navbar fixed-top navbar-expand-lg navbar-light" style={{backgroundColor: navColor}}>
      <a className="navbar-brand ms-2" href="/"><img className={brandImage} src={logo}/></a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mx-auto">
          {menu.map(item=>
            <MenuListItem item={item} key={item.menu_id}/>
          )}
          <li className="nav-item">
            <Link to={"/blog"} className="nav-link text-black" href="#">Blog</Link>
          </li>
          <MenuListItem item={global.config.menu_about} key="about"/>
        </ul>
        <div className="row row-cols-md-auto me-2">
          <Search type={"nav"}/>
          <a href="#" className="btn btn-keppa my-2 my-sm-0" data-toggle="modal" data-target="#enquiryModal" data-bs-toggle="modal" data-bs-target="#enquiryModal" onClick={(e) => dispatch(update('none'))}><i className="fa fa-plane"></i> Plan Your Trip</a>
        </div>
      </div>
    </nav>
  );
}

export default Topnav;