import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux'
import '../config.js';
import { createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';

function EnquiryForm(props) {
  const type = props.type;
  const [travelDate, setTravelDate] = useState('');
  const [days, setDays] = useState('0');
  const [hotel, setHotel] = useState('None');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('None');
  const [mobile, setMobile] = useState('');
  const [remark, setRemark] = useState('');
  const [adults, setAdults] = useState('0');
  const [childrenNo, setChildrenNo] = useState('0');
  const [children, setChildren] = useState([]);
  const [code, setCode] = useState('');
  const [success, setSuccess] = useState(false);
  const myEnquiryForm = useRef();
  const packageId = useSelector(state => state.enquiry.package);  

  useEffect(()=>{
    const c = parseInt(childrenNo);
    let temp = [];
    for(let i=1;i<=c;i++){
      let indexStr = "th";
      if(i === 1){
        indexStr = "st";
      }else if(i === 2){
        indexStr = "nd";
      }else if(i === 3){
        indexStr = "rd";
      }
      temp.push({"child": '', 'index': i, value: '', 'indexStr': i + indexStr});
    }
    setChildren(temp);
  }, [childrenNo]);

  const updateChildren = (value, index) => {
    const temp = [];
    children.forEach(child => {
      if(child.index === parseInt(index)){
        child.value = value;
      }
      temp.push(child);
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let packageLink = 'None';
    if(props.packageId){
      packageLink = global.config.public_server + '/package/' + props.packageId
    }else if(packageId != 'none'){
      packageLink = global.config.public_server + '/package/' + packageId
    }
    if(travelDate && days != '0' && name && email && country != "None" && mobile && remark && adults != "0"){
      axios.post(global.config.api_server+'enquiry',createFormData({
        travelDate: travelDate,
        days: days,
        hotel: hotel,
        name: name,
        email: email,
        country: country,
        mobile: code + " - " + mobile,
        remark: remark,
        adults: adults,
        childrenNo: childrenNo,
        packageLink:packageLink,
        children: JSON.stringify(children)
      })).then(res => {
        if(res.status === 200 && res.data.status === "success"){
          myEnquiryForm.current.reset();
          if(type === "sidebar"){
            const enquiryButton = document.getElementById('enquiry-msg-btn-js');
            enquiryButton.click();
          }else{
            setSuccess(true);
          }
        }else{
          alertService.error(res.data.error);
        }
      }).catch(err => {
        alertService.error(err);
      });
    }else{
      alertService.error('All the fields are required');
    }
  }

  const daysArray = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31+'];
  const paxArray = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15+'];
  const hotelArray = ['5 Star (Luxury Hotels)','5 Star (Premium Hotels)', '5 Star (Super Deluxe Hotels)', '4 Star (Deluxe Hotels)', '3 Star (Standard Hotels)', 'Budget'];
  
  const countryCodes = global.config.country_code;

  const dateOfTravel = useRef();
  if(dateOfTravel && dateOfTravel.current){
    dateOfTravel.current.type = 'text';
  }
  
  const handleFocus = (v) => {
    dateOfTravel.current.type = 'date';
  }

  const handleBlur = (v) => {
    v ? dateOfTravel.current.type = 'date': dateOfTravel.current.type = 'text';
  }

  let classVariant = "col-md-6";
  if(type === "sidebar"){
    classVariant = "col-md-12";
  }

  const updateCode = (v) => {
    countryCodes.forEach(c => {
      if(c.country === v){
        setCode(c.code);
        return;
      }
    });
  }

  if(success){
    return( 
      <div className="row">
        <div className="col-md-12">
          <p>Thank you very much for contacting us. We have noted your details and one of our tour specialists will contact you very soon!</p>
          <button className={'btn btn-keppa float-end'} type="reset" data-dismiss="modal" onClick={(e) => setSuccess(false)}>Close</button>
        </div>
      </div>
    )
  }else{
   return(
    <form ref={myEnquiryForm}>
      <div className="row">
        <div className="col-md-12">
          <div className="mb-3">
            <input type="date" required ref={dateOfTravel}  maxLength="50" onFocus={(e) => handleFocus(e.target.value)} onBlur={(e) => handleBlur(e.target.value)} name="travelDate" className="form-control" id="travelDate" placeholder="Tentative Date of travel" onChange={(e) => setTravelDate(e.target.value)}/>
          </div>
        </div>
        <div className={classVariant}>
          <div className="mb-3">
            <select maxLength="50" required name="days" className="form-control" id="days" onChange={(e) => setDays(e.target.value)}>
              <option value="0">No. of Days *</option>
              {daysArray.map(d=>
                <option key={d} value={d}>{d}</option>
              )}
            </select>
          </div>
        </div>
        <div className={classVariant}>
          <div className="mb-3">
            <select maxLength="50" required name="hotel" className="form-control" id="hotel" onChange={(e) => setHotel(e.target.value)}>
              <option value="None">Select Hotel Category</option>
              {hotelArray.map(h=>
                <option key={h} value={h}>{h}</option>
              )}
            </select>
          </div>
        </div>
        <div className={classVariant}>
          <div className="mb-3">
            <select maxLength="50" required name="adults" className="form-control" id="adults" onChange={(e) => setAdults(e.target.value)}>
              <option value="0">No. of Adults *</option>
              {paxArray.map(d=>
                <option key={d} value={d}>{d}</option>
              )}
            </select>
          </div>
        </div>
        <div className={classVariant}>
          <div className="mb-3">
            <select maxLength="50" required name="childrenNo" className="form-control" id="childrenNo" onChange={(e) => setChildrenNo(e.target.value)}>
              <option value="0">No. of Children</option>
              {paxArray.map(h=>
                <option key={h} value={h}>{h}</option>
              )}
            </select>
          </div>
        </div>
        {children.map(child=>
          <div className="col-md-12">
            <div className="mb-3">
              <label>{child.indexStr} Child Age</label>
              <input key={child.index} type="number" required maxLength="100" className="form-control" placeholder={child.indexStr + ' Child Age'} onChange={(e) => updateChildren(e.target.value, child.index)}/>
            </div>
          </div>
        )}
        <div className="col-md-12">
          <div className="mb-3">
            <input type="text" maxLength="100" required name="name" className="form-control" id="name" placeholder="Name *" onChange={(e) => setName(e.target.value)}/>
          </div>
        </div>
        <div className="col-md-12">
          <div className="mb-3">
            <input type="email" maxLength="100" required name="email" className="form-control" id="email" placeholder="Email *" onChange={(e) => setEmail(e.target.value)}/>
          </div>
        </div>
        <div className={classVariant}>
          <div className="mb-3">
            <select maxLength="50" name="country" required className="form-control" id="country" onChange={(e) => {setCountry(e.target.value);updateCode(e.target.value)}}>
              <option value="None">Your Country *</option>
              {countryCodes.map((c)=>
                <option key={c.iso} value={c.country}>{c.country}</option>
              )}
            </select>
          </div>
        </div>
        <div className={classVariant}>
          <div className="mb-3 input-group">
          <div className="input-group-prepend">
            <span className="input-group-text" id="isd-code">+{code}</span>
          </div>
            <input type="text" maxLength="20" required name="mobile" className="form-control" id="mobile" placeholder="Contact No. *" onChange={(e) => setMobile(e.target.value)}/>
          </div>
        </div>
        <div className="col-md-12">
          <div className="mb-3">
            <textarea maxLength="500" name="remark" required className="form-control" id="remark" placeholder="Tell us more..." onChange={(e) => setRemark(e.target.value)}></textarea>
          </div>
        </div>
        <hr/>
        <div className="col-md-12">
          <button className="btn btn-default float-end" type="reset" data-dismiss="modal">Close</button>
          <button className={'btn btn-keppa float-end'} type="button" onClick={(e) => handleSubmit(e)}>Submit</button>
          {type === "sidebar" &&
            <a href="#" className="no-display" id="enquiry-msg-btn-js" data-toggle="modal" data-target="#enquiryMsgModal" data-bs-toggle="modal" data-bs-target="#enquiryMsgModal"> Success</a>
          }
        </div>
      </div>
    </form>
    )
  }
}

export default EnquiryForm;