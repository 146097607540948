import React, {useEffect} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Dashboard from './dashboard.js';
import Topnav from '../components/topnav.js';
import Footer from '../components/footer.js';

import { alertService } from '../services/alert.service.js';
import {Alert} from '../components/alert.js';
import Tour from './tour.js';
import Enquiry from '../components/enquiry.js';
import Pack from './pack.js';
import SearchPage from './searchPage.js';
import About from './about.js';
import Contact from './contact.js';
import Company from './company.js';
import Faq from './faq.js';
import PrivacyPolicy from './privacyPolicy.js';
import Terms from './terms.js';
import ScrollToTop from '../components/scrollToTop.js';
import Blog from './blog.js';

function Base(props) {
  
  return(
      <BrowserRouter>
        <Alert />
        <Topnav/>
        <Routes>
          <Route path="*" element={<Dashboard />} />
          <Route index path='/' element={ <Dashboard /> } ></Route>
          <Route index path='/tour/:id' element={ <Tour /> } ></Route>
          <Route index path='/package/:id' element={ <Pack /> } ></Route>
          <Route index path='/search' element={ <SearchPage /> } ></Route>
          <Route index path='/about' element={ <About /> } ></Route>
          <Route index path='/contact' element={ <Contact /> } ></Route>
          <Route index path='/company' element={ <Company /> } ></Route>
          <Route index path='/faq' element={ <Faq /> } ></Route>
          <Route index path='/privacy-policy' element={ <PrivacyPolicy /> } ></Route>
          <Route index path='/terms' element={ <Terms /> } ></Route>
          <Route index path='/blog' element={ <Blog /> } ></Route>
        </Routes>
        <Enquiry/>
        <Footer/>
        <ScrollToTop/>
      </BrowserRouter>
  );
}

export default Base;