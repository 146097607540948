import React, {useState} from 'react';
import '../config.js';
import Progress from '../components/progress.js';
import DefaultHeader from '../components/defaultHeader.js';
import img from '../assets/blog.jpg';
import Association from '../components/association.js';

function Blog() {
    const [loading, setLoading] = useState(false);

    if(loading){
        return <Progress/>;
    }else{
        return(
            <div className="about-content">
                <DefaultHeader img={img} heading={"Blog"}/>

                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <h1 className='text-center'>Blog</h1>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="row justify-content-center">
                            <p className='text-center'>Coming Soon</p>
                    </div>
                </div>
                <Association/>
            </div>
        )
    }
}

export default Blog;