import React from 'react';
import '../config.js';
import { Carousel } from 'react-responsive-carousel';
import Search from '../components/search.js';
import img from '../assets/home.jpg';

function DefaultHeader(props) {
  let backgroundImage = props.img;
  if(!backgroundImage){
    backgroundImage = img;
  }
  let heading = props.heading;
  if(!heading){
    heading = "Welcome to Keppa Travel";
  }
  return(
    <div className="main-header">
        <Carousel showArrows={false} showThumbs={false} autoPlay={false} infiniteLoop={false} interval={3000} showIndicators={false}>
            <div>
                <img src={backgroundImage} />
                <div className="row justify-content-center">
                  <div className='heading'>
                      <h1 className="banner-text text-white text-poppins-bold">{heading}</h1>
                  </div>
                </div>
            </div>
        </Carousel>
    </div>
  )
}

export default DefaultHeader;