import React, {useState} from 'react';
import '../config.js';
import Progress from '../components/progress.js';
import DefaultHeader from '../components/defaultHeader.js';
import img from '../assets/company.jpg';
import Association from '../components/association.js';

function Company() {
    const [loading, setLoading] = useState(false);

    if(loading){
        return <Progress/>;
    }else{
        return(
            <div className="about-content">
                <DefaultHeader img={img} heading="Company Profile"/>

                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <h1 className='text-center'>Keppa Travel: Explore the immensity of India</h1>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="row">
                        <div className='col-md-12'>
                            <h4>Introduction</h4>
                            <p>An extension of Curiosites Tour India, at <strong><u>Keppa Travel</u></strong>, we bring India's magic to the world by
curating unforgettable travel experiences for foreign tourists. As one of India's premier travel
companies, we take immense pride in offering personalized tours catering to international travelers
seeking to explore India's vibrant culture, ancient history, natural wonders, and spiritual essence.
Whether you're looking to visit the iconic Taj Mahal, experience the bustling streets of Delhi, or relax
on the pristine beaches of Kerala, Keppa Travel ensures that each journey is a seamless blend of
adventure, comfort, and cultural immersion.</p>
<p>Our company is officially recognized and certified, holding a <strong>GST number</strong>, and an <strong>MSME certificate</strong>,
the Government of India. These accreditations are a testament to our credibility, compliance, and
commitment to delivering world-class travel services while adhering to the highest industry
standards.</p>
<h4>Our Core Values</h4>
<ol>
    <li><strong>Authenticity:</strong>We believe in providing genuine experiences that reflect India's true spirit.
From visiting hidden gems to engaging with local communities, we strive to offer an
authentic view of Indian culture.</li>
    <li><strong>Personalization:</strong>Every traveler is unique, and so is every Keppa Travel tour. We work closely
with our clients to ascertain their interests, preferences, and travel goals in the design of
customized itineraries.</li>
    <li><strong>Sustainability:</strong>Keppa Travel is committed to promoting sustainable tourism. We prioritize
eco-friendly practices and support local communities, ensuring that our tours have a positive
impact on the environment and the people of India.</li>
    <li><strong>Excellence:</strong>We aim to exceed expectations at every step, from our professional guides to
our carefully selected accommodations and transport options. Every detail is taken care of to
ensure a seamless and enjoyable experience.</li>
</ol>
<h4>Our Accreditations</h4>
<p>At Keppa Travel, our official certifications underscore our commitment to professionalism and
adherence to legal and operational standards:</p>
<ol>
    <li><strong>GST Registered:</strong>We are a fully compliant company with a registered <strong>Goods and Services Tax
(GST) number</strong>, ensuring transparency and adherence to Indian tax regulations. All our
transactions are conducted with proper invoicing, reflecting our dedication to integrity and
legality in business operations.</li>
    <li><strong>MSME Certificate:</strong>As a certified Micro, Small &amp; <strong>Medium Enterprises (MSME)</strong> company, we
are recognized by the Government of India for our contribution to the travel and tourism
industry. This certification reflects our commitment to supporting sustainable tourism and
local communities.</li>
</ol>
<h4>Why Choose Keppa Travel?</h4>
<ol>
    <li><strong>Tailored Itineraries:</strong>We know that no two travelers are the same. Whether you are a history
buff, nature enthusiast, foodie, or spiritual seeker, we create customized itineraries that
reflect your interests.</li>
    <li><strong>Experienced Guides:</strong>Our expert local guides are well-versed in India's history, culture, and
traditions. They provide insightful commentary and a deeper understanding of the places
you visit, enriching your travel experience.</li>
    <li><strong>Seamless Travel:</strong>From arranging flights and accommodation to local transportation, we
    handle all the logistics so you can focus on enjoying your journey.</li>
    <li><strong>24/7 Support:</strong>Our team is available around the clock to assist you with any queries or
    concerns during your trip.</li>
    <li><strong>Cultural Immersion:</strong>We go beyond standard sightseeing by providing opportunities to
interact with local communities, participate in cultural activities, and experience the real
India.</li>
    <li><strong>Safety and Comfort:</strong>Your safety and well-being are our top priorities. We carefully select
    hotels, transportation, and guides to ensure you travel in comfort and security.</li>
</ol>
<h4>Our Services</h4>
<h5>1. Guided Tours</h5>
<p>Keppa Travel offers a wide array of guided tours that span the length and breadth of India. Our tours
cover the most iconic destinations in the country, ensuring that travelers experience the diversity of
India's landscapes, history, and culture. Our knowledgeable guides provide valuable insights and
personal stories, giving tourists an enriching experience.</p>
<p><strong>Popular guided tour options include:</strong></p>
<ol>
    <li><strong>The Golden Triangle Tour (Delhi, Agra, Jaipur):</strong>Perfect for first-time visitors, this tour covers
some of India's most famous landmarks, including the Taj Mahal, Jaipur's Amber Fort, and
Delhi's India Gate.</li>
    <li><strong>Rajasthan Heritage Tour:</strong>Discover the royal palaces, forts, and desert landscapes of
    Rajasthan, India's land of kings.</li>
    <li><strong>Spiritual India Tour:</strong>Explore the spiritual heart of India with visits to Varanasi, Rishikesh, and
    Bodh Gaya.</li>
    <li><strong>Kerala Backwater and Ayurveda Retreat:</strong>For a relaxing experience, travel through Kerala's
    serene backwaters and rejuvenate with traditional Ayurveda therapies.</li>
    <li><strong>Many more………</strong></li>
</ol>
<h5>2. Adventure and Wildlife Tours</h5>
<p>For thrill-seekers and nature lovers, we offer bespoke adventure and wildlife tours. India's diverse
geography, from the snow-capped Himalayas to dense rainforests, offers endless opportunities for
adventure and wildlife exploration.</p>
<p><strong>Top options include:</strong></p>
<ol>
    <li><strong>Himalayan Treks:</strong>Explore the breathtaking landscapes of the Himalayas with treks to
    popular destinations like Leh-Ladakh, Manali, and Sikkim.</li>
    <li><strong>Cultural &amp; Family tour:</strong>Keppa Travel takes great pride in organizing Cultural and Family
Tours that provide enriching experiences for all ages, creating lifelong memories for families
and individuals alike. These tours are designed to immerse travelers in the history, heritage,
and traditions of a destination, offering a perfect blend of educational, entertaining, and
engaging activities for family members of all ages.</li>
    <li><strong>Wildlife Safaris:</strong>Visit India's famous national parks, such as Ranthambore, Jim Corbett, and
    Bandhavgarh, for an unforgettable wildlife safari. Spot tigers, leopards, elephants, and more.</li>
    <li><strong>Beach Escapes:</strong>Enjoy water sports and relaxation at India's best beach destinations like Goa,
    Andaman Islands, and the Lakshadweep archipelago.</li>
    <li><strong>Many more………</strong></li>
</ol>
<h5>3. Cultural and Heritage Tours</h5>
<p>India’s rich history and cultural diversity are a major draw for foreign tourists. Our cultural tours
provide an immersive experience into India’s traditions, art, architecture, and festivals.</p>
<p><strong>Highlights of our cultural tours:</strong></p>
<ol>
    <li><strong>Temple Tours:</strong>Explore India’s ancient temples in Tamil Nadu, Odisha, and Karnataka,
    marveling at the intricate architecture and spiritual significance.</li>
    <li><strong>Festival Tours:</strong>Experience the vibrancy of India’s festivals, such as Holi, Diwali, and the
    Pushkar Camel Fair, for an authentic cultural experience.</li>
    <li><strong>Art and Handicraft Tours:</strong>Discover India’s traditional arts and crafts in regions like Jaipur
    (block printing), Varanasi (silk weaving), and Kutch (handicrafts).</li>
    <li><strong>Many more………</strong></li>
</ol>
<h5>4. Luxury Travel</h5>
<p>For travelers seeking a premium experience, Keppa Travel offers luxury tours that cater to all your
desires. Stay in five-star hotels, heritage palaces, and exclusive resorts while traveling in style.</p>
<p><strong>Luxury options include:</strong></p>
<ol>
    <li><strong>Palace Stays:</strong>Enjoy royal treatment by staying in heritage palaces in Rajasthan.</li>
    <li><strong>Luxury Train Journeys:</strong>Experience India on board luxurious trains like the Maharajas’
    Express and Palace on Wheels, offering world-class amenities and curated excursions.</li>
    <li><strong>Cruise Tours:</strong>Explore India’s coastline and backwaters in comfort with our exclusive cruise
    options.</li>
    <li><strong>Many more………</strong></li>
</ol>
<h5>5. Special Interest Tours</h5>
<p>Keppa Travel also offers tours catering to special interests such as wellness, culinary experiences,
photography, and more. These tours are designed for travelers looking for unique and focused
experiences in India.</p>
<p><strong>Popular special interest tours:</strong></p>
<ol>
    <li><strong>Yoga and Meditation Retreats:</strong>India is the birthplace of yoga, and we offer retreats in
    Rishikesh, Kerala, and Goa.</li>
    <li><strong>Culinary Tours:</strong>Explore India’s diverse cuisine with cooking classes, market visits, and
    culinary experiences in cities like Mumbai, Delhi, and Kolkata.</li>
    <li><strong>Photography Tours:</strong>Capture India’s stunning landscapes, wildlife, and culture with guided
    photography tours.</li>
    <li><strong>Many more………</strong></li>
</ol>
<h4>Our Commitment to Responsible Tourism</h4>
<p>At Keppa Travel, we are deeply committed to responsible and sustainable tourism practices. We
strive to minimize the environmental impact of our tours and support local communities by
employing local guides, using eco-friendly accommodations, and promoting cultural heritage
preservation. We believe that travel should not only benefit the traveler but also contribute
positively to the destinations and people they visit.</p>
<h5>How to Book</h5>
<p>Booking your dream trip to India with Keppa Travel is easy. Visit our website at
<a href="www.keppatravel.com">www.keppatravel.com</a>, where you can explore our range of tours, read detailed itineraries, and
make a reservation. Our user-friendly booking process allows you to customize your tour to fit your
preferences. Just fill out the inquiry form given on the website mentioned above. If you need
assistance, our team is just an email or phone call away.</p>
<h4>Conclusion</h4>
<p>India is a land of endless possibilities, from its ancient temples and palaces to its bustling cities and
tranquil landscapes. At Keppa Travel, we are passionate about helping foreign tourists experience
the best of what India has to offer. With our personalized services, expert knowledge, and
commitment to excellence, we are your ideal travel partner for discovering India in all its glory.</p>
<p><strong>Let Keppa Travel take you on a journey of a lifetime! Explore the immensity of India with us today
at <a href="www.keppatravel.com">www.keppatravel.com</a></strong></p>
                        </div>
                    </div>
                </div>
                <Association/>
            </div>
        )
    }
}

export default Company;