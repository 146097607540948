import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../config.js';
import { theme, createFormData } from '../services/helper.js';
import { alertService } from '../services/alert.service.js';
import EnquiryForm from './enquiryForm.js';

function Enquiry(props) {
  return(
    <div>
      <div className="modal fade" id="enquiryModal" tabIndex="-1" role="dialog" aria-labelledby="enquiry-popup" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center" id="enquiry-popup">Enquiry</h5>
              <button type="button" data-bs-dismiss="modal" class="btn btn-link" data-dismiss="modal" aria-label="Close"><i className="fa fa-close"></i></button>
            </div>
            <form>
              <div className="modal-body">
                <EnquiryForm/>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="modal fade" id="enquiryMsgModal" tabIndex="-1" role="dialog" aria-labelledby="enquiry-popup" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title text-center" id="enquiry-msg-popup">Enquiry</h5>
              <button type="button" data-bs-dismiss="modal" class="btn btn-link" data-dismiss="modal" aria-label="Close"><i className="fa fa-close"></i></button>
            </div>
            <form>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <p>Thank you very much for contacting us. We have noted your details and one of our tour specialists will contact you very soon!</p>
                    <button className={'btn btn-keppa float-end'} type="reset" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Enquiry;