import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../config.js';
import { alertService } from '../services/alert.service.js';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import defaultTagImg from '../assets/default_tag.jpg';

function ShowcaseCarousel(props) {
  const [menu, setMenu] = useState([]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

  useEffect(()=>{
    let uri = global.config.api_server+'menu?showcase=1';
      axios.get(uri)
      .then(res => {
          if(res.status === 200 && res.data.status === "success"){
            const temp = res.data.menu;
            setMenu(temp)
          }
      }).catch(err => {
          alertService.error(err);
      });
    }, []);
  
  return(
    <div className="container">
      <div className="showcase-carousel">
        <Carousel responsive={responsive} infinite={true} autoPlay={true} showDots={false} draggable={true} swipeable={false} autoPlaySpeed={3000}>
          {menu.map(item=>
            <div className='item' key={item.menu_id} style={{background: item.color}}>
              {item.tags.length > 0 &&
                <Link to={"/tour/"+item.tags[0].identifier}>
                  {item.images.length > 0 ?
                    <img src={global.config.asset_server + item.images[0].uri}/>:
                    <img src={defaultTagImg}/>
                  }
                  <div className="caption">
                    <h4 className="text-center text-white">{item.label}</h4>
                    <p className="text-center">Packages: {item.tags[0].package_count}</p>
                  </div> 
                </Link>
              }
            </div>
          )}
        </Carousel >
      </div>
    </div>
  );
}

export default ShowcaseCarousel;