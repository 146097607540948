import React, {useState} from 'react';
import '../config.js';
import Progress from '../components/progress.js';
import DefaultHeader from '../components/defaultHeader.js';
import img from '../assets/about.jpg';
import Association from '../components/association.js';

function About() {
    const [loading, setLoading] = useState(false);

    if(loading){
        return <Progress/>;
    }else{
        return(
            <div className="about-content">
                <DefaultHeader img={img} heading="Who We Are"/>

                <div className="container mt-5">
                    <div className="row">
                        <div className='col-md-12'>
                        <h4>About Keppa Travel</h4>
                        <p>Welcome to <strong>Keppa Travel</strong>! With over 20 years of experience in the travel and tourism industry, we
are proud to be one of India's leading tour operators, specializing in creating unique and immersive
travel experiences for foreign tourists. Our journey began with a passion for showcasing the beauty,
culture, and diversity of India, and today, we continue to craft personalized tours that capture the
essence of this incredible country.</p>
<p>At Keppa Travel, we believe in providing more than just a trip; we deliver unforgettable memories.
From iconic landmarks like the Taj Mahal to hidden gems known only to locals, our expertly curated
itineraries ensure that you experience India in its most authentic form. Our team of experienced
travel professionals is dedicated to making every aspect of your journey seamless and enjoyable,
with a focus on safety, comfort, and cultural immersion.</p>
<h4>Who We Are</h4>
                        <p>Keppa Travel is a leading India-based travel company specializing in tours for foreign tourists. With a
deep understanding of the diversity and richness that India has to offer, we curate tailor-made travel
experiences that showcase the essence of this vast and culturally rich land. Our team consists of
travel experts, experienced guides, and local partners who are passionate about sharing the beauty
of India with the world.</p>
<p>Our mission is to provide foreign tourists with a holistic experience of India, ensuring that every
aspect of their journey is memorable, from the moment they land in India until the time they depart.
With a commitment to safety, comfort, and personalized service, Keppa Travel aims to offer unique
itineraries that cater to both first-time visitors and seasoned travelers alike.</p>
<p>With years of experience and a deep passion for travel, we strive to create unique, customized
itineraries that cater to the varied interests of our clients. Whether you are a history enthusiast, an
adventure seeker, a wellness traveler, or simply looking for a relaxing getaway, we ensure every
aspect of your journey is planned to perfection.</p>
<p>Certified and holding an <strong>MSME certificate</strong> (<u>Registered with Govt. of India</u>) and fully compliant with
<strong>GST regulations</strong>, Keppa Travel is a trusted name in the industry. Our deep-rooted knowledge and
connections across India allow us to offer a wide variety of travel experiences, including cultural and
heritage tours, adventure trips, luxury escapes, and wellness retreats. Whether you're a first-time
visitor or a seasoned traveler, we tailor each itinerary to suit your interests, ensuring a perfect
balance of exploration and relaxation.</p>
<p>Join us on a journey through India, and let our more than 20 years of expertise guide you to the
heart of this magnificent land. Explore with Keppa Travel-your gateway to incredible India!</p>
                        </div>
                    </div>
                </div>
                <Association/>
            </div>
        )
    }
}

export default About;