import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../config.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ShowcaseCarousel from '../components/showcaseCarousel.js';
import DashboardSection from '../components/dashboardSection.js';
import Search from '../components/search.js';
import home from '../assets/home.jpg';
import Association from '../components/association.js';
import { useDispatch } from 'react-redux'
import { update } from '../slices/enquiry.js';

function Dasboard() {
    const [loading, setLoading] = useState(false);
    const [sections, setSections] = useState([]);
    const dispatch = useDispatch();

    useEffect(()=>{
        let uri = global.config.api_server+'section';
        axios.get(uri)
        .then(res => {
            if(res.status === 200 && res.data.status === "success"){
            const temp = res.data.sections;
            setSections(temp)
            }
        }).catch(err => {
            alertService.error(err);
        });
    }, []);

    if(loading){
        return <Progress/>;
    }else{
        return(
            <div className="dashboard-content">
                <div className="main-header">
                    <Carousel showArrows={true} showThumbs={false} autoPlay={true} infiniteLoop={true} interval={3000} showIndicators={false}>
                        <div>
                            <img src={home} />
                            <div className="row justify-content-center">
                                <Search/>
                            </div>
                        </div>
                    </Carousel>
                </div>
                
                <ShowcaseCarousel/>

                {sections.map(section=>
                    <DashboardSection item={section} key={section.section_id}/>
                )}
                <div className="dashboard-section" style={{backgroundColor: "rgb(159, 200, 211)"}}>
                    <div className="container">
                        <a href="#" className="btn btn-keppa my-2 my-sm-0 float-end" data-toggle="modal" data-target="#enquiryModal" data-bs-toggle="modal" data-bs-target="#enquiryModal" onClick={(e) => dispatch(update('none'))}><i className="fa fa-plane"></i> Plan Your Trip</a>
                        <h2 className="section-heading">
                            Need to customize the trip? Just "Whats App" us or hit this button
                        </h2>
                    </div>
                </div>
                <Association/>
            </div>
        )
    }
}

export default Dasboard;