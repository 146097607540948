import React, {useState, useEffect, useRef} from 'react';
import {Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import axios from 'axios';
import '../config.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import defaultPackImg from '../assets/default_pack.jpg';
import EnquiryForm from '../components/enquiryForm.js';
import PackageSlider from '../components/packageSlider.js';
import { useDispatch } from 'react-redux'
import { update } from '../slices/enquiry.js';

function Pack() {
    const [loading, setLoading] = useState(false);
    const { id } = useParams();
    const [pack, setPack] = useState([]);
    const [packages, setPackages] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(()=>{
        let uri = global.config.api_server+'package?id='+id;
        axios.get(uri)
        .then(res => {
            if(res.status === 200 && res.data.status === "success"){
                const temp = res.data.package;
                temp.cover_images = temp.images;
                if(temp.cover_images.length > 1){
                    temp.cover_images.splice(0,1);
                }
                setPack(temp);
            }else{
                navigate("/");
            }
        }).catch(err => {
            alertService.error(err);
            navigate("/");
        });
    }, [id]);

    useEffect(()=>{
        if(pack && pack.tags && pack.tags.length > 0){
            let tagStr = "0";
            pack.tags.forEach(tag => {
                tagStr = tagStr + "," + tag.identifier;
            });
            let uri = global.config.api_server+'packages?t='+tagStr+"&limit=10";
            axios.get(uri)
            .then(res => {
                if(res.status === 200 && res.data.status === "success"){
                    const temp = [];
                    res.data.packages.forEach(p => {
                        if(p.package_id != pack.package_id){
                            temp.push(p);
                        }
                    });
                    setPackages(temp);
                }
            }).catch(err => {
                alertService.error(err);
            });
        }
    
      }, [pack]);

    if(loading){
        return <Progress/>;
    }else{
        return(
            <div className="pack-content">
                {pack && pack.images &&
                    <div className="main-header">
                        {pack.cover_images.length === 0 ?
                            <Carousel showArrows={false} showThumbs={false} autoPlay={false} infiniteLoop={true} showIndicators={false}>
                                <div>
                                    <img src={defaultPackImg} />
                                    <div className="row justify-content-center">
                                        <div className='heading'>
                                            <h1 className="text-poppins-bold text-white">{pack.heading}</h1>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        :
                            <Carousel showArrows={true} showThumbs={true} autoPlay={true} infiniteLoop={true} interval={3000} showIndicators={false}>
                                {pack.cover_images.map(im=>
                                    <div key={im.image_id}>
                                        <img src={global.config.asset_server+ im.uri} />
                                        <div className="row justify-content-center">
                                            <div className='heading'>
                                                <h1 className="text-poppins-bold text-white">{pack.heading}</h1>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </Carousel>
                        }
                    </div>
                }
                <div className="container">
                    <div className="row">
                        <div className='col-md-8'>
                            <h2>{pack.heading}</h2>
                            {(pack.description != null && pack.description != undefined && pack.description != "null") &&
                                <div className="mt-2" dangerouslySetInnerHTML={{ __html: pack.description }} />
                            }
                        </div>
                        <div className='col-md-4'>
                            <h5 className='text-gray-800'><i className="fa fa-clock-o"></i> {pack.duration}</h5>
                            <p className='text-gray-800'><i className="fa fa-globe"></i> &nbsp;{pack.destination}</p>
                            <a href="#" className="btn btn-keppa my-2 my-sm-0 w-100" data-toggle="modal" data-target="#enquiryModal" data-bs-toggle="modal" data-bs-target="#enquiryModal" onClick={(e) => dispatch(update(pack.identifier))}><i className="fa fa-paper-plane"></i> Send Enquiry</a>
                        </div>
                    </div>
                </div>

                <div className="container mt-2">
                    <div className="row">
                        <div className="col-md-8">
                            {(pack && pack.itineraries && pack.itineraries.length > 0) &&
                                <div>
                                    <h4><i className="fa fa-suitcase"></i> Itinerary Details</h4>
                                    {pack.itineraries.map(itinerary => 
                                        <div className="accordion mt-1" key={itinerary.itinerary_id} id={"itinerary-"+itinerary.itinerary_id}>
                                            <div className="card">
                                            <div className="card-header" id={"itineraryDay-"+itinerary.itinerary_id}>
                                                <h2 className="mb-0">
                                                <button className="btn btn-link w-100 text-start" type="button" data-toggle="collapse" data-target={"#itineraryBody-"+itinerary.itinerary_id} aria-expanded="false" aria-controls={"itineraryBody-"+itinerary.itinerary_id}>
                                                    <strong><span className='text-black'>Day {itinerary.day}:</span> {itinerary.heading}</strong>
                                                </button>
                                                </h2>
                                            </div>
                                        
                                            <div id={"itineraryBody-"+itinerary.itinerary_id} className="collapse" aria-labelledby={"itineraryDay-"+itinerary.itinerary_id} data-parent={"#itinerary-"+itinerary.itinerary_id}>
                                                <div className="card-body">
                                                    <div dangerouslySetInnerHTML={{ __html: itinerary.description }} />
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            }
                            <div className="accordion mt-4" id={"itinerary-includes"}>
                                <div className="card">
                                    <div className="card-header" id={"itineraryDay-includes"}>
                                        <h2 className="mb-0">
                                        <button className="btn btn-link w-100 text-start" type="button" data-toggle="collapse" data-target={"#itineraryBody-includes"} aria-expanded="false" aria-controls={"itineraryBody-includes"}>
                                            <i className="fa fa-check-circle"></i> Includes
                                        </button>
                                        </h2>
                                    </div>
                                
                                    <div id={"itineraryBody-includes"} className="collapse" aria-labelledby={"itineraryDay-includes"} data-parent={"#itinerary-includes"}>
                                        <div className="card-body">
                                            <div dangerouslySetInnerHTML={{ __html: pack.includes }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion mt-3" id={"itinerary-excludes"}>
                                <div className="card">
                                    <div className="card-header" id={"itineraryDay-excludes"}>
                                        <h2 className="mb-0">
                                        <button className="btn btn-link w-100 text-start" type="button" data-toggle="collapse" data-target={"#itineraryBody-excludes"} aria-expanded="false" aria-controls={"itineraryBody-excludes"}>
                                            <i className="fa fa-times-circle"></i> Excludes
                                        </button>
                                        </h2>
                                    </div>
                                
                                    <div id={"itineraryBody-excludes"} className="collapse" aria-labelledby={"itineraryDay-excludes"} data-parent={"#itinerary-excludes"}>
                                        <div className="card-body">
                                            <div dangerouslySetInnerHTML={{ __html: pack.excludes }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card enquiry-form">
                                <div className="card-header">
                                    <h5 className="mb-0 text-center text-white">
                                        Enquiry Form
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <EnquiryForm type={"sidebar"} packageId={pack.identifier}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                {(packages && packages.length > 0) &&
                    <div className='container-fluid mt-5'>
                        <div className="justify-content-center row">
                            <h4>Recommended</h4>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <PackageSlider packages={packages} type="PACKAGE"/>
                            </div>
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default Pack;