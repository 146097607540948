import React, {useState, useEffect, useRef} from 'react';
import {Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import axios from 'axios';
import '../config.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import PackageTeaser from '../components/packageTeaser.js';
import defaultTagImg from '../assets/default_tag.jpg';

function Tour() {
    const [loading, setLoading] = useState(false);
    const { id, subnav } = useParams();
    const [tour, setTour] = useState([]);
    const [packages, setPackages] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        let uri = global.config.api_server+'tag?id='+id;
        axios.get(uri)
        .then(res => {
            if(res.status === 200 && res.data.status === "success"){
                const temp = res.data.tag;
                temp.cover_images = temp.images;
                if(temp.cover_images.length > 1){
                    temp.cover_images.splice(0,1);
                }
                setTour(temp);
            }else{
                navigate("/");
            }
        }).catch(err => {
            alertService.error(err);
            navigate("/");
        });
    }, [id]);

    useEffect(()=>{
        if(tour && tour.identifier){
          let uri = global.config.api_server+'packages?t='+tour.identifier;
          axios.get(uri)
          .then(res => {
              if(res.status === 200 && res.data.status === "success"){
              const temp = res.data.packages;
              setPackages(temp)
              }
          }).catch(err => {
              alertService.error(err);
          });
        }
    
      }, [tour]);

    if(loading){
        return <Progress/>;
    }else{
        return(
            <div className="tour-content">
                {tour && tour.images &&
                    <div className="main-header">
                        {tour.cover_images.length === 0?
                            <Carousel showArrows={false} showThumbs={false} autoPlay={false} infiniteLoop={true} showIndicators={false}>
                                <div>
                                    <img src={defaultTagImg} />
                                    <div className="row justify-content-center">
                                        <div className='heading'>
                                            <h1 className="text-poppins-bold text-white">{tour.label}</h1>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        :
                        <Carousel showArrows={true} showThumbs={true} autoPlay={true} infiniteLoop={true} interval={3000} showIndicators={false}>
                            {tour.cover_images.map(im=>
                                <div key={im.image_id}>
                                    <img src={global.config.asset_server+ im.uri} />
                                    <div className="row justify-content-center">
                                        <div className='heading'>
                                            <h1 className="text-poppins-bold text-white">{tour.label}</h1>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </Carousel>
                        }
                    </div>
                }
                {(tour.description != null && tour.description != undefined && tour.description != "null") &&
                    <div className="container">
                        <div className="row">
                            <div className="p-2" dangerouslySetInnerHTML={{ __html: tour.description }} />
                        </div>
                    </div>
                }
                <div className="container">
                    <div className="row justify-content-center">
                        <h1>{tour.label}</h1>
                    </div>
                    <div className="row mt-3">
                        {packages.map(pack=>
                            <div key={pack.package_id} className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-3">
                                <PackageTeaser item={pack}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export default Tour;