import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import '../config.js';
import { alertService } from '../services/alert.service.js';
import PackageSlider from './packageSlider.js';

function DashboardSection(props) {
  const [selectedTab, setSelectedTab] = useState(null);
  const [packages, setPackages] = useState([]);

  useEffect(()=>{
    if(section.child.length > 0){
      setSelectedTab(section.child[0]);
    }
  }, []);

  useEffect(()=>{
    if(selectedTab && selectedTab.tags.length > 0){
      let uri = global.config.api_server+'packages?t='+selectedTab.tags[0].identifier+"&limit=5";
      axios.get(uri)
      .then(res => {
          if(res.status === 200 && res.data.status === "success"){
            const temp = res.data.packages;
            setPackages(temp)
          }
      }).catch(err => {
          alertService.error(err);
      });
    }

  }, [selectedTab]);

  const section = props.item;
  
  return(
    <div className="dashboard-section" style={{backgroundColor:section.color}}>
      <div className="container">
        <h3 className='section-heading text-poppins-bold'>{section.heading}</h3>
        <div className='btns'>
          {section.child.map(tab=>
            <span key={tab.tab_id}>
              {selectedTab && selectedTab.tab_id === tab.tab_id?
              <button className="btn btn-keppa mb-2" type="button" onClick={(e) => setSelectedTab(tab)}> {tab.heading}</button>:
              <button className="btn btn-outline-keppa mb-2" type="button" onClick={(e) => setSelectedTab(tab)}> {tab.heading}</button>}
            </span>
          )}
        </div>
        {selectedTab &&
          <PackageSlider packages={packages} type="PACKAGE"/>
        }
        {selectedTab && selectedTab.tags.length > 0 &&
          <div className='row justify-content-center mt-4'>
            <u><Link to={"/tour/"+selectedTab.tags[0].identifier} className='section-link'><h6>View All {selectedTab.heading} <i className="fa fa-long-arrow-right"></i></h6></Link></u>
          </div>
        }
      </div>
    </div>
  );
}

export default DashboardSection;