import React, {useState} from 'react';
import '../config.js';
import {Link} from 'react-router-dom';
import Progress from '../components/progress.js';
import DefaultHeader from '../components/defaultHeader.js';
import img from '../assets/policy.jpg';

function PrivacyPolicy() {
    const [loading, setLoading] = useState(false);

    if(loading){
        return <Progress/>;
    }else{
        return(
            <div className="about-content">
                <DefaultHeader img={img} heading="Privacy Policy"/>

                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <h1 className='text-center'>Privacy Policy</h1>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="row">
                        <div className='col-md-12'>
                            <h3>Effective Date: November 2024</h3>
                            <p>At Keppa Travel, we are committed to safeguarding your privacy and ensuring that your personal
information is handled responsibly. This Privacy Policy outlines how we collect, use, and protect your
information when you use our services, including our website and booking services.</p>
<p>By using our services, you consent to the practices outlined in this Privacy Policy.</p>
<h4>1. Information We Collect</h4>
<p>We collect information to facilitate your travel bookings and improve our services. The types of
personal information we collect may include:</p>
<p><strong>a. Personal Information:</strong></p>
<ul>
    <li><strong>Contact Information:</strong>Full name, email address, phone number, etc.</li>
    <li><strong>Booking Details:</strong>Information related to your travel arrangements, such as international
    flight details, your accommodation choice, preferences, special requests, and itineraries.</li>
    <li><strong>Identification Information:</strong>Passport numbers, visa details, and other identification
    documents necessary for travel (when required urgently)</li>
    <li><strong>Payment Information:</strong>Credit card or debit card details, billing address, and other payment-
    related information (However all the information would be secured with us)</li>
</ul>
<p><strong>b. Automatically Collected Information:</strong></p>
<ul>
    <li><strong>Device Information:</strong>Details about your device such as IP address, browser type, operating
    system, and device identifiers.</li>
    <li><strong>Usage Data:</strong>Information about how you use our website, such as pages visited, search
    queries, and interactions with our site.</li>
    <li><strong>Cookies:</strong>Small text files stored on your device that help us remember your preferences and
    enhance your experience on our website.</li>
</ul>
<h4>2. How We Use Your Information</h4>
<p>We use the information we collect for the following purposes:</p>
<ul>
    <li><strong>Booking and Travel Services:</strong>To process and manage your travel bookings, reservations, and
    itineraries.</li>
    <li><strong>Customer Service:</strong>To respond to your inquiries, provide support, and address any issues
    related to your travel plans.</li>
    <li><strong>Payment Processing:</strong>To securely process your transactions, prevent fraud, and facilitate
    refunds if necessary.</li>
    <li><strong>Marketing:</strong>To send you promotional materials, newsletters, and offers, provided you have
opted-in to receive such communications. You can opt out at any time by following the
unsubscribe instructions in the email.</li>
    <li><strong>Legal Compliance:</strong>To comply with applicable laws and regulations, including legal
    obligations related to travel security and financial transactions.</li>
</ul>
<h4>3. Sharing Your Information</h4>
<p>We do not sell or rent your personal information. However, we may share your information in the
following circumstances:</p>
<ul>
    <li><strong>Third-Party Service Providers:</strong>We may share your information with airlines, hotels, tour
operators, car rental companies, and other travel service providers to fulfill your travel
bookings.</li>
    <li><strong>Payment Processing Partners:</strong>Your payment details may be shared with banks, credit card
    processors, and fraud prevention services to complete transactions securely.</li>
    <li><strong>Business Partners:</strong>If you book a service or package through a partner or affiliate, we may
    share relevant information with them to process your booking.</li>
    <li><strong>Legal Requirements:</strong>We may disclose your personal information to regulatory authorities,
government agencies, or law enforcement in compliance with legal requirements, and court
orders, or to protect our rights and interests.</li>
</ul>
<h4>4. Data Security</h4>
<p>Keppa Travel takes the security of your personal information seriously. We use encryption, secure
servers, and other technical safeguards to protect your data against unauthorized access, loss, or
misuse. However, no online transmission or storage system is completely secure, and we cannot
guarantee the absolute security of your information.</p>
<h4>5. Data Retention</h4>
<p>We retain your personal information for as long as necessary to fulfill the purposes for which it was
collected, including for the provision of services, compliance with legal obligations, or resolving
disputes. Once the information is no longer needed, it will be securely deleted or anonymized.</p>
<h4>6. Your Rights</h4>
<p>You have certain rights regarding your personal information, which include:</p>
<ul>
    <li><strong>Access:</strong>You can request a copy of the personal data we hold about you.</li>
    <li><strong>Rectification:</strong>You can request the correction of any inaccurate or incomplete data.</li>
    <li><strong>Erasure:</strong>You can request the deletion of your data under certain conditions.</li>
    <li><strong>Data Portability:</strong>You have the right to receive your data in a machine-readable format.</li>
</ul>
<p>To exercise any of these rights, please contact us <Link to={"/contact"}>here</Link></p>
<h4>7. Cookies and Tracking Technologies</h4>
<p>Our website uses cookies and other tracking technologies to collect information about your
browsing activities and preferences. This helps us improve website functionality, enhance user
experience, and analyze web traffic. You can control your cookie preferences through your browser
settings, but disabling cookies may affect the functionality of our website.</p>
<h4>8. Children&#39;s Privacy</h4>
<p>Our services are not directed to children under the age of 18, and we do not knowingly collect
personal information from individuals under this age. If we become aware that a child has provided
us with personal information, we will take steps to delete such data.</p>
<h4>9. International Data Transfers</h4>
<p>As a global travel agency, we may transfer your personal data to countries outside of your home
country to fulfill travel bookings. We take steps to ensure that your data is handled securely and in
accordance with this Privacy Policy, regardless of where it is processed.</p>
<h4>10. Changes to this Privacy Policy</h4>
<p>We may update this Privacy Policy from time to time to reflect changes in our practices or legal
requirements. The updated policy will be posted on our website with the effective date at the top.
We encourage you to review this page periodically to stay informed about how we protect your
information.</p>
<h4>11. Contact Us</h4>
<p>If you have any questions or concerns about this Privacy Policy or our data practices, please contact
us at <Link to={"/contact"}>here</Link></p>
<p>By using our services, you acknowledge that you have read and understood this Privacy Policy.</p>

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default PrivacyPolicy;