import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import Base from './pages/base.js';
import './config.js';
import { Provider } from 'react-redux'
import store from './store.js';

function AuthHandler(){
  return(
    <Provider store={store}>
      <Base/>
    </Provider>
  );
}

const container = document.getElementById('showcase');
const root = createRoot(container);
root.render(<AuthHandler />);