import { createSlice } from '@reduxjs/toolkit'

export const enquirySlice = createSlice({
  name: 'enquiry',
  initialState: {
    package: 'none',
  },
  reducers: {
    update: (state, action) => {
      state.package = action.payload
    }
  },
})

export const { update } = enquirySlice.actions

export default enquirySlice.reducer