import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import '../config.js';
import defaultTagImg from '../assets/default_tag.jpg';

function MenuListItem(props) {
  const item = props.item;
  const [show, setShow] = useState(false);
  const [onLink, setOnLink] = useState(false);
  const [onItem, setOnItem] = useState(false);

  useEffect(()=>{
      if(onLink || onItem){
        setShow(true);
      }else{
        setShow(false);
      }
    }, [onLink, onItem]);

    let menuWidth = window.innerWidth;
    if(menuWidth < 850){
      menuWidth = menuWidth-15;
    }

  return(
    <li className="nav-item dropdown" onMouseLeave={(e) => setOnLink(false)} onMouseEnter={(e) => setOnLink(true)}>
      <a className="nav-link dropdown-toggle text-black" href="#">
        {item.label}
      </a>
      {show && 
        <div className="dropdown-menu-container">
          {item.style === 'CATEGORY_LIST' &&
            <div className="dropdown-menu-lg p-2" style={{width: menuWidth}} onMouseLeave={(e) => setOnItem(false)} onMouseEnter={(e) => setOnItem(true)}>
              <div className="row justify-content-center">
                  {item.child.map(category=>
                    <div className="col-lg col-md-6 category-item pt-3" key={category.menu_id}>
                      <p><strong>{category.label}</strong></p>
                        {category.child.map(m=>
                          <span key={m.menu_id}>
                          {m.tags.length > 0 &&
                            <Link reloadDocument to={"/tour/"+m.tags[0].identifier} className="nav-link" key={m.menu_id}>{m.label}</Link>
                          }
                          </span>
                        )}
                      {category.tags.length > 0 &&
                        <p><strong><Link to={"/tour/"+category.tags[0].identifier} className="nav-link">{"View All " + category.label}</Link></strong></p>
                      }
                    </div>
                  )}
              </div>
            </div>
          }
          {item.style === 'THUMBNAIL' &&
            <div className="dropdown-menu-lg p-2" style={{width: menuWidth}} onMouseLeave={(e) => setOnItem(false)} onMouseEnter={(e) => setOnItem(true)}>
              <div className="row justify-content-center">
                  {item.child.map(category=>
                    <div className="col-xlg-1 col-lg-2 col-md-6 col-sm-12 col-xs-12 thumbnail-item pt-3" key={category.menu_id}>
                      {category.tags.length > 0 &&
                        <div>
                          {category.images.length > 0 ?
                            <Link to={"/tour/"+category.tags[0].identifier} className="nav-link"><img src={global.config.asset_server + category.images[0].uri}/></Link>:
                            <Link to={"/tour/"+category.tags[0].identifier} className="nav-link"><img src={defaultTagImg}/></Link>
                          }
                          <p className="text-center"><Link to={"/tour/"+category.tags[0].identifier} className="nav-link">{category.label}</Link></p>
                        </div>
                      }
                    </div>
                  )}
              </div>
            </div>
          }
          {item.style === 'LIST' &&
            <div className="dropdown-menu-sm p-2">
              <div className="row justify-content-center">
                <div className="col-sm category-item pt-3">
                  {item.child.map(linkItem=>
                    <Link reloadDocument to={linkItem.link} className="nav-link" key={linkItem.menu_id}>{linkItem.label}</Link>
                  )}
                </div>
              </div>
            </div>
          }
        </div>
      }
    </li>
  )
}

export default MenuListItem;