import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import { useDispatch } from 'react-redux'
import '../config.js';
import defaultPackImg from '../assets/default_pack.jpg';
import { update } from '../slices/enquiry.js';

function PackageTeaser(props) {
  const item = props.item;
  const dispatch = useDispatch();

  return(
    <div className='package-teaser'>
      {item.images.length > 0 ?
        <img src={global.config.asset_server + item.images[0].uri}/>:
        <img src={defaultPackImg}/>
      }
      {(item.label && item.label != "null" && item.label.toLowerCase() != 'none') && 
        <div className="package-label" style={{backgroundColor: item.label_color}}>{item.label}</div>
      }
      <div className="caption">
        <h4>{item.heading}</h4>
        <p><i className="fa fa-clock-o"></i> {item.duration}</p>
        <p className="destination"><i className="fa fa-globe"></i> {item.destination}</p>
        <div className="row justify-content-center">
          <Link to={"/package/"+item.identifier} className="btn btn-outline-keppa me-lg-2 me-md-0 col-lg-6 col-md-12" type="button"> Details <i className="fa fa-long-arrow-right"></i></Link>
          <a href="#" className="btn btn-keppa col-lg-5 col-md-12" data-toggle="modal" data-target="#enquiryModal" data-bs-toggle="modal" data-bs-target="#enquiryModal" onClick={(e) => dispatch(update(item.identifier))}>Enquiry</a>
        </div>
      </div> 
    </div>
  )
}

export default PackageTeaser;