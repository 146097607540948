import React, {useState} from 'react';
import '../config.js';
import Progress from '../components/progress.js';
import DefaultHeader from '../components/defaultHeader.js';
import img from '../assets/terms.jpg';

function Terms() {
    const [loading, setLoading] = useState(false);

    if(loading){
        return <Progress/>;
    }else{
        return(
            <div className="about-content">
                <DefaultHeader img={img} heading="Terms and Conditions"/>

                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <h1 className='text-center'>Terms and Conditions of Keppa Travel</h1>
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="row">
                        <div className='col-md-12'>
                        <p>Welcome to Keppa Travel! These Terms and Conditions (&quot;Terms&quot;) govern your access and use of our
services, including but not limited to our website (www.keppatravel.com), booking services, and any
other related offerings provided by Keppa Travel (collectively, the &quot;Services&quot;). By using our Services,
you agree to these Terms in full. If you do not agree with these Terms, please refrain from using our
Services.</p>
<h4>1. Company Information</h4>
<p>Keppa Travel is a travel and tourism company with 20 years of experience, providing tailored travel
services, holiday packages, and tour operations.</p>
<h4>2. Eligibility</h4>
<p>You must be at least 18 years of age to use our Services. By booking with us, you warrant that you
have the legal capacity to enter into binding contracts.</p>
<h4>3. Services Offered</h4>
<p>Keppa Travel provides the following services:</p>
<ul>
    <li>Flight and accommodation bookings</li>
    <li>Holiday packages</li>
    <li>Customized tours and itineraries</li>
    <li>Travel consultancy services</li>
</ul>
<p>All services are subject to availability and are not guaranteed until confirmed by Keppa Travel.</p>
<h4>4. Booking and Payment</h4>
<ul>
    <li>All bookings must be made through our official website, www.keppatravel.com, or through
    an authorized representative of Keppa Travel.</li>
    <li>A deposit may be required to secure your booking, with the full amount due by a specified
    date before your departure.</li>
    <li>Payment must be made in the currency specified at the time of booking.</li>
    <li>Failure to pay within the specified time may result in cancellation of your booking.</li>
</ul>
<h4>5. Cancellations and Refunds</h4>
<ul>
    <li><strong>Customer Cancellations:Cancellations by customers must be made in writing and may be
subject to cancellation fees. The amount refunded will depend on the timing of the
cancellation about the date of travel. Please review specific terms during booking.</strong></li>
    <li><strong>Keppa Travel Cancellations:</strong>If Keppa Travel cancels your booking due to unforeseen
circumstances (such as natural disasters, political unrest, etc.), you will be entitled to a full
refund or the option to reschedule.</li>
    <li><strong>Refund:</strong>When it comes to refunds for unused or underutilized services (those that are paid for and
canceled well in advance), the amount of the refund will be determined using the above-
described cancellation criteria, and the money will be returned to the original payer.
Refunds applied to credit card payments will only be issued to that same credit card. Please
be aware that owing to banking regulations, the refund process could take two to eight
weeks.</li>
</ul>
<p>When reimbursements are given because services are unavailable, no deductions will be
made (except for transaction fees). We shall provide comparable alternatives well in
advance of the start date if the desired services are not accessible during the required term.</p>
<h4>6. Changes to Bookings</h4>
<ul>
    <li>Any changes to bookings, including flight times, accommodation, or activities, are subject to
    availability and may incur additional fees.</li>
    <li>Changes requested less than 7 days before the travel date may not be accommodated.</li>
</ul>
<h4>7. Travel Documents and Visa Requirements</h4>
<p>It is your responsibility to ensure that you have valid travel documents, including passports, visas,
and any other required documentation, before the start of your journey. Keppa Travel is not liable
for any disruptions caused by invalid or missing documents.</p>
<h4>8. Travel Insurance</h4>
<p>Keppa Travel strongly recommends that you purchase travel insurance to cover unforeseen events
such as cancellations, illness, or lost luggage. Keppa Travel is not responsible for any costs incurred
as a result of not having insurance.</p>
<h4>9. Liability</h4>
<ul>
    <li>Keppa Travel acts as an intermediary for the different suppliers (airlines, hotels, transport
companies, etc.). While we ensure that we work with reputable partners, we are not liable
for any delays, cancellations, or issues caused by third-party providers.</li>
<li>Keppa Travel is not liable for any injury, loss, or damage you may suffer while traveling.</li>
</ul>
<h4>10. Force Majeure</h4>
<p>Keppa Travel is not responsible for events beyond its control, including but not limited to natural
disasters, war, pandemics, strikes, or any other circumstances that may affect travel services.</p>
<h4>11. Complaints and Dispute Resolution</h4>
<p>If you have any complaints during your travel, please notify us immediately so we can resolve the
issue as soon as possible. If a dispute arises, it will be governed by the laws of the jurisdiction where
Keppa Travel is registered, and any legal proceedings must be brought in that jurisdiction.</p>
<h4>12. Intellectual Property</h4>
<p>All content on the Keppa Travel website, including text, images, logos, and trademarks, is the
intellectual property of Keppa Travel or its content providers. Unauthorized use of our content is
prohibited.</p>
<h4>13. Amendments to the Terms and Conditions</h4>
<p>Keppa Travel reserves the right to modify these Terms and Conditions at any time. Any changes will
be effective immediately upon posting on our website. Your continued use of the Services signifies
your acceptance of the updated Terms.</p>
<h4>14. Privacy Policy</h4>
<p>Your privacy is important to us. Please review our Privacy Policy to understand how we collect, use,
and protect your personal information.</p>
<h4>15. Contact Information</h4>
<p>If you have any questions or concerns about these Terms and Conditions, please contact us:</p>
<p>Keppa Travel</p>
<p>Email: <a href="mailto:tourtravel000@gmail.com">tourtravel000@gmail.com</a></p>
<p>Phone: +919220419122 | +919953249821</p>
<p>Website: www.keppatravel.com</p>
<h4>16. Mode of Payment:</h4>
<p>Overseas advance payment can be made through Bank / Wire Transfer to our bank account. The
transfer/processing charges levied by the bank need to be deposited in addition to the payment
amount.</p>
<p><strong>We do not accept payments through cheque.</strong></p>
<p>Upon arrival in India, the payment can be made through currency notes or Master / Visa / Diners /
American Express Credit Card.</p>
<p>No Show / Early Departure / Cancellation Policy:</p>
<p>In case of cancellation of tour/travel services due to any avoidable/unavoidable reason/s we must be
informed in writing well in advance.</p>
<p>Cancellation charges would be effective from the date we receive the letter in writing and would be
as follows: -</p>
<ul>
    <li><strong>60 days</strong> before arrival - 10% of the total tour/service cost (except the transaction charges)</li>
    <li><strong>40 days</strong> before arrival - 25% of the total tour/service cost (except the transaction charges)</li>
    <li><strong>15 days</strong> before arrival - 30% of the total tour/service cost (except the transaction charges)</li>
    <li><strong>10 days</strong> before arrival - 40% of the total tour/service cost (except the transaction charges)</li>
    <li><strong>72 hours</strong> before arrival - 50% of the total tour/service cost (except the transaction charges)</li>
    <li><strong>Less than 72 hours or no show - NO REFUND.</strong></li>
    <li>In case of special train journeys, special airfare journeys and peak season hotel bookings a
    separate cancellation policy is applicable which would be advised as and when required.</li>
    <li>In case of &#39;No Show&#39;- No Refund During &quot;Peak-Season,&quot; which is defined by the density of
tourist inflow in each region, and may vary from place to place and hotel to hotel, any
particular hotel may implement a different cancellation policy. The travel agent is bound to
follow the hotel specific cancellation policy; and in case of variation from the above-stated
policy, the excess in cancellation charges will be borne by the client and not by the travel
agent. Modifications/Cancellations sent directly to the hotel will not be honored.</li>
<li>For outbound Travel from India, Visa processing charges are fully non-refundable in case of
cancellation of the trip and also in case of Visa denial by the respective embassy.</li>
</ul>
<h4>General Terms:</h4>
<p>Keppatravel acts only in the capacity of agent for the hotels, airlines, transporters, railways &amp;
contractors providing other services &amp; all exchange orders, receipts, contracts &amp; tickets issued by us
are issued subject to terms &amp; conditions under which these services are provided by them.</p>
<p>The hotel ratings given on our websites are established by qualified local authorities and do not
reflect our opinion about the hotel or the service rendered by the hotel.</p>
<p>All itineraries are sample itineraries, intended to give you a general idea of the likely trip schedule.
Numerous factors such as weather, road conditions, the physical ability of the participants, etc. may
dictate itinerary changes either before the tour or while on the trail. We reserve the right to change
any schedule in the interest of the trip participants’ safety, comfort &amp; general well-being.</p>
<p>We shall not be responsible for any delays &amp; alterations in the program or expenses incurred –
directly or indirectly – due to natural hazards, flight cancellations, accidents, breakdown of

machinery or types of equipment, breakdown of transport, weather, sickness, landslides, political
closures or any untoward incidents.</p>
<p>We shall not be responsible for any loss, injury, or damage to person, property, or otherwise in
connection with any accommodation, transportation, or other services, resulting – directly or
indirectly – from any act of GOD, dangers, fire, accident, breakdown in machinery or equipment,
breakdown of transport, wars, civil disturbances, strikes, riots, thefts, pilferages, epidemics, medical
or custom department regulations, defaults, or any other causes beyond our control.</p>
<p>We do not have any insurance policy covering the expenses for accidents, sickness, loss due to theft,
or any other reasons. Visitors are advised to seek such insurance arrangements in their home
country. All baggage &amp; personal property/s at all times are at the client’s risk.</p>
<p><strong>Complaints regarding reserved services:</strong></p>
<p>We will be happy to consider any recommendations from the customer to raise the caliber of the
services and accommodations offered. We value your opinions, suggestions, and/or grievances
about the services you have used that you have purchased from us.</p>
<p>We have made every effort to ensure that all services are rendered by our websites&#39; descriptions
and to an acceptable standard. Nevertheless, if the provider makes changes that we are unaware of,
TuskTravel.com is not liable for them.</p>
<p>Keppatravel disclaims any liability for any service interruptions rendered by outside vendors, such as
lodging establishments. While we use reasonable caution when staying at a hotel, we cannot, under
any circumstances, guarantee the caliber of services offered there.</p>
<p><strong>E &amp; O E – Errors and Omissions Excepted</strong></p>
<p>If a guest or member of the company overlooks a typing or human error, Keppatravel shall not be
held liable.</p>
<p>By booking with Keppa Travel, you acknowledge that you have read, understood, and agreed to
these Terms and Conditions.</p>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Terms;