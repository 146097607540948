import React, {useRef, useLayoutEffect} from 'react';
import '../config.js';
import PackageTeaser from './packageTeaser.js';
import TagTeaser from './tagTeaser.js';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function PackageSlider(props) {
  const packages = props.packages;
  const type = props.type;

  const packageContainer = useRef();
  const leftNav = useRef();
  const rightNav = useRef();
  let leftPosition = 0;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1400 },
      items: 5
    },
    largeDesktop: {
      breakpoint: { max: 1400, min: 1200 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 1200, min: 992 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 992, min: 768 },
      items: 3
    },
    largeMobile: {
      breakpoint: { max: 768, min: 576 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 1
    }
  };
  
  if(packages.length > 0){
    return(
      <div className={'package-slider'}>
        <Carousel responsive={responsive} infinite={true} autoPlay={true} showDots={false} draggable={true} swipeable={false} autoPlaySpeed={3000}>
          {packages.map(pack=>
            <div className="package-item" key={pack.package_id || pack.tag_id}>
              {type === 'PACKAGE'?
                <PackageTeaser item={pack}/>
              :
                <TagTeaser item={pack}/>
              }
            </div>
          )}
        </Carousel>
      </div>
    );
  } else return null;
}

export default PackageSlider;