import React, {useState, useEffect, useRef} from 'react';
import {useSearchParams } from 'react-router-dom';
import axios from 'axios';
import '../config.js';
import { alertService } from '../services/alert.service.js';
import Progress from '../components/progress.js';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import PackageTeaser from '../components/packageTeaser.js';
import PackageSlider from '../components/packageSlider.js';
import Search from '../components/search.js';
import img from '../assets/home.jpg';

function SearchPage(props) {
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const q = searchParams.get('q');
    const [packages, setPackages] = useState([]);
    const [tags, setTags] = useState([]);

    useEffect(()=>{
        if(q){
            let uri = global.config.api_server+'packages?q='+q+"&limit=50";
            axios.get(uri)
            .then(res => {
                if(res.status === 200 && res.data.status === "success"){
                    const temp = res.data.packages;
                    setPackages(temp);
                }
            }).catch(err => {
                alertService.error(err);
            });
            let uri2 = global.config.api_server+'tags?q='+q;
            axios.get(uri2)
            .then(res => {
                if(res.status === 200 && res.data.status === "success"){
                    const temp = res.data.tags;
                    setTags(temp);
                }
            }).catch(err => {
                alertService.error(err);
            });
        }
      }, [q]);

    if(loading){
        return <Progress/>;
    }else{
        return(
            <div className="search-content">
                <div className="main-header">
                    <Carousel showArrows={false} showThumbs={false} autoPlay={false} infiniteLoop={true} showIndicators={false}>
                        <div>
                            <img src={img} />
                            <div className="row justify-content-center">
                                <Search value={q}/>
                            </div>
                        </div>
                    </Carousel>
                </div>

                <div className="container mt-5">
                    <div className="row justify-content-center">
                        <h3>Search result for: {q}</h3>
                    </div>
                </div>

                {(packages && packages.length > 0) ?
                    <div className='container mt-4'>
                        <div className="row">
                            {packages.map(pack=>
                                <div key={pack.package_id} className="col-lg-3 col-md-4 col-sm-6 col-xs-12 mb-4">
                                    <PackageTeaser item={pack}/>
                                </div>
                            )}
                        </div>
                    </div>:
                    <div className='container mt-4'>
                        <div className="row justify-content-center">
                            <p>No result found</p>
                        </div>
                    </div>
                }
                
                
                {(tags && tags.length > 0) &&
                    <div className='container mt-5'>
                        <div className="row">
                            <div className="col-md-12">
                                <PackageSlider packages={tags} type="TAG"/>
                            </div>
                        </div>
                    </div>
                }

            </div>
        )
    }
}

export default SearchPage;