import React, {useState, useEffect, useRef} from 'react';
import {Link} from 'react-router-dom';
import '../config.js';
import defaultTagImg from '../assets/default_tag.jpg';

function TagTeaser(props) {
  const item = props.item;

  return(
    <div className='package-teaser'>
      {item.images && item.images.length > 0 ?
        <img src={global.config.asset_server + item.images[0].uri}/>
        :
        <img src={defaultTagImg}/>
      }
      <div className="caption text-center">
        <Link to={"/tour/"+item.identifier} className="btn btn-outline-keppa btn-block" type="button"> View All {item.label} <i className="fa fa-long-arrow-right"></i></Link>
      </div> 
    </div>
  )
}

export default TagTeaser;